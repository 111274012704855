import { storeToRefs } from 'pinia'
import { Capability, CAPABILITY_CONFIG, PageName, UserGroups } from '../config'

export class InvalidConfigException extends Error {}

export type CapabilityChecker = (
  pageName: PageName,
  capability: Capability
) => boolean

export default function useRights() {
  const { auth, userGroups, isUserAdmin, overrideUserGroups } =
    storeToRefs(useUserStore())

  /**
   * Check if the current user has the required capability.
   *
   * This function throws an error if the page or capability is not configured.
   */
  function hasCapability(pageName: PageName, capability: Capability): boolean {
    console.log('hasCapability', userGroups.value)
    const capabilityGroups = getCapabilityGroups(pageName, capability)
    return userMatchesCapabilityGroup(userGroups.value, capabilityGroups)
  }

  /**
   * Check if the current user can access a certain page.
   */
  function canAccessPage(pageName: PageName): boolean {
    const pageCapabilities = CAPABILITY_CONFIG.get(pageName)
    if (pageCapabilities === undefined) {
      return false
    }
    for (const capabilityGroups of Object.values(pageCapabilities)) {
      if (userMatchesCapabilityGroup(userGroups.value, capabilityGroups)) {
        return true
      }
    }
    return false
  }

  /**
   * Override the user group for the current user.
   *
   * This is an admin-only functionality.
   */
  function overrideUserGroup(userGroup: UserGroups): void {
    if (isUserAdmin.value === false) {
      return
    }
    overrideUserGroups.value = [userGroup]
    userGroups.value = [userGroup]
  }

  /**
   * Override the user group for the current user.
   *
   * This is an admin-only functionality.
   */
  async function resetOverride(): Promise<void> {
    overrideUserGroups.value = []
    const tokenResult = await auth.value?.currentUser?.getIdTokenResult()
    userGroups.value = tokenResult?.claims['userGroups'] as UserGroups[]
  }

  return { hasCapability, canAccessPage, overrideUserGroup, resetOverride }
}

function getCapabilityGroups(
  pageName: PageName,
  capability: Capability
): UserGroups[] {
  const pageCapabilities = CAPABILITY_CONFIG.get(pageName)
  if (pageCapabilities === undefined) {
    throw new InvalidConfigException(
      `No configuration found for page ${pageName}`
    )
  }
  const capabilityGroups = pageCapabilities[capability]
  if (capabilityGroups === undefined) {
    throw new InvalidConfigException(
      `No configuration found for capability ${capability}`
    )
  }
  return capabilityGroups
}

function userMatchesCapabilityGroup(
  userGroups: UserGroups[],
  capabilityGroups: UserGroups[]
): boolean {
  if (userGroups.includes(UserGroups.Admin)) {
    return true
  }
  for (const userGroup of userGroups) {
    if (capabilityGroups.includes(userGroup)) {
      return true
    }
  }
  return false
}
