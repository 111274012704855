import useRights from '../composables/userRights';
<template>
  <nav
    class="sticky top-0 z-50 mb-5 flex items-center gap-[128px] border-b bg-white px-6"
  >
    <div class="flex grow select-none flex-row items-center gap-4">
      <img
        :src="logoSrc"
        alt="Logo"
        class="max-h-14"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      />

      <MaterialIcon
        v-if="user?.email"
        v-ripple="{
          pt: {
            root: {
              class: 'bg-aubergine-200'
            }
          }
        }"
        icon="apps"
        class="p-ripple rounded-full p-4"
        color="aubergine-dark-500"
        @click="menuVisible = !menuVisible"
      >
      </MaterialIcon>
    </div>

    <Sidebar
      v-model:visible="menuVisible"
      class="bg-green mt-[114px] translate-y-0 bg-softlight-500"
      position="full"
      :show-close-icon="false"
      :modal="false"
      :pt="{
        transition: {
          enterFromClass: 'translate-y-10 opacity-0',
          leaveToClass: 'translate-y-10 opacity-0',
          enterActiveClass: 'transition-[opacity,transform]',
          leaveActiveClass: 'transition-[opacity,transform]'
        }
      }"
    >
      <div
        class="flex h-[calc(100%-114px)] flex-col items-center justify-center gap-8 text-aubergine-dark-500"
      >
        <div>
          <h3 class="mb-1">{{ $t('header.tasks') }}</h3>
          <div class="grid grid-cols-5 gap-4">
            <HeaderMenuItem link="/controle-levering" @click="closeMenu">
              <FileCheckOutlineIcon />
              {{ $t('header.deliveryControl') }}
            </HeaderMenuItem>
            <HeaderMenuItem link="/printen" @click="closeMenu">
              <PrinterOutlineIcon />
              {{ $t('header.labelPrinting') }}
            </HeaderMenuItem>
            <HeaderMenuItem link="/bestellingen" @click="closeMenu">
              <BasketOutlineIcon />
              {{ $t('header.orderRequest') }}
            </HeaderMenuItem>
            <HeaderMenuItem link="/bestanden-inladen" @click="closeMenu">
              <FileUploadIcon />
              {{ $t('header.loadFiles') }}
            </HeaderMenuItem>

            <HeaderMenuItem link="/nazendingen" @click="closeMenu">
              <PackageVariantIcon />
              {{ $t('header.backorders') }}
            </HeaderMenuItem>
            <HeaderMenuItem link="/parallel-bestellen" @click="closeMenu">
              <MaterialIcon
                icon="local_mall"
                color="aubergine-dark-500"
                class="mx-auto block"
              />
              {{ $t('header.parallelOrder') }}
            </HeaderMenuItem>
            <HeaderMenuItem
              v-if="canAccessPage(PageName.PHARMACIST_INSPECTION)"
              link="/apothekers-controle"
              @click="closeMenu"
            >
              <MaterialIcon
                icon="mystery"
                color="aubergine-dark-500"
                class="mx-auto block"
              />
              {{ $t('header.pharmacistsInspection') }}
            </HeaderMenuItem>
            <HeaderMenuItem
              v-if="canAccessPage(PageName.PRESCRIPTION_PROCESSING)"
              link="/receptverwerking"
              @click="closeMenu"
            >
              <MaterialIcon
                icon="prescriptions"
                color="aubergine-dark-500"
                class="mx-auto block"
              />
              {{ $t('header.prescriptionProcessing') }}
            </HeaderMenuItem>
          </div>
        </div>
        <div>
          <h3 class="mb-1">{{ $t('header.pages') }}</h3>
          <div class="grid grid-cols-5 gap-4" @click="menuVisible = false">
            <HeaderMenuItem link="/klantprofiel" @click="closeMenu">
              <AccountCircleIcon />
              {{ $t('header.clientProfile') }}
            </HeaderMenuItem>
            <HeaderMenuItem link="/" @click="closeMenu">
              <MagnifyIcon />
              {{ $t('header.deliveries') }}
            </HeaderMenuItem>
            <HeaderMenuItem link="/leveringen-overzicht" @click="closeMenu">
              <ProgressClockIcon />
              {{ $t('header.parcelOverview') }}
            </HeaderMenuItem>
          </div>
        </div>
      </div>
    </Sidebar>

    <div class="flex items-center">
      <AutoVersion class="mr-2" />
      <FirebaseLogin />
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { PageName } from '~/config'

const { user } = storeToRefs(useUserStore())
const menuVisible = ref(false)
const hover = ref(false)
const logoSrc = computed((): string =>
  hover.value ? '/images/logo-hover.svg' : '/images/logo.svg'
)

const { canAccessPage } = useRights()

function closeMenu() {
  menuVisible.value = false
}
</script>

<style lang="css">
.material-design-icon__svg {
  margin: 0 auto;
}
</style>
