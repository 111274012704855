import { PageName } from '~/config'
import { Controller } from '~/features/controller'
import { AppUserService } from './appUserService'
import { AproDataService } from './aproDataService'
import { InspectionService } from './inspectionService'
import { LockService } from './lock/lockService'
import { MedicationAgreementService } from './medicationAgreementService'
import { PrescriptionLineService } from './prescriptionLineService'
import { TaskService } from './taskService'
import { MedicineArticleService } from './medicineArticleService'
import { InsuranceCompanyService } from './insuranceCompanyService'

export class CommunicationManagerContainer {
  protected lockService?: LockService
  protected appUserService?: AppUserService
  protected aproDataService?: AproDataService
  protected inspectionService?: InspectionService
  protected medAgreementService?: MedicationAgreementService
  protected prescriptionLineService?: PrescriptionLineService
  protected taskService?: TaskService
  protected medicineArticleService?: MedicineArticleService
  protected insuranceCompanyService?: InsuranceCompanyService

  private masterControllers: Map<string, Controller> = new Map()
  private controllers: Controller[] = []

  public get LockService() {
    if (!this.lockService) {
      this.lockService = new LockService()
    }
    return this.lockService
  }

  public get AppUserService() {
    if (!this.appUserService) {
      this.appUserService = new AppUserService()
    }
    return this.appUserService
  }

  public get AproDataService() {
    if (!this.aproDataService) {
      this.aproDataService = new AproDataService()
    }
    return this.aproDataService
  }

  public get InspectionService() {
    if (!this.inspectionService) {
      this.inspectionService = new InspectionService()
    }
    return this.inspectionService
  }

  public get MedicationAgreementService() {
    if (!this.medAgreementService) {
      this.medAgreementService = new MedicationAgreementService()
    }
    return this.medAgreementService
  }

  public get PrescriptionLineService() {
    if (!this.prescriptionLineService) {
      this.prescriptionLineService = new PrescriptionLineService()
    }
    return this.prescriptionLineService
  }

  public get TaskService() {
    if (!this.taskService) {
      this.taskService = new TaskService()
    }
    return this.taskService
  }

  public get MedicineArticleService() {
    if (!this.medicineArticleService) {
      this.medicineArticleService = new MedicineArticleService()
    }
    return this.medicineArticleService
  }

  public get InsuranceCompanyService() {
    if (!this.insuranceCompanyService) {
      this.insuranceCompanyService = new InsuranceCompanyService()
    }
    return this.insuranceCompanyService
  }

  public addController(controller: Controller) {
    if (controller.isMaster) {
      this.masterControllers.set(controller.pageName, controller)
    }

    this.controllers.push(controller)
  }

  public getMasterController<T extends Controller>(controller: Controller): T {
    const masterController = this.masterControllers.get(controller.pageName)
    if (!masterController) {
      throw new Error(`Master not found for controller`)
    }
    return masterController as T
  }

  public activatePage(pageName: PageName) {
    const services = [
      this.LockService,
      this.AppUserService,
      this.AproDataService,
      this.InspectionService,
      this.MedicationAgreementService,
      this.PrescriptionLineService,
      this.TaskService,
      this.MedicineArticleService,
      this.InsuranceCompanyService
    ]

    for (const service of services) {
      service.activatePage(pageName)
    }

    for (const controller of this.controllers) {
      controller.initializeController()
    }
  }
}

export const CommunicationManager = new CommunicationManagerContainer()
