export enum PageName {
  PHARMACIST_INSPECTION = 'apothekers-controle',
  LOAD_FILES = 'bestanden-inladen',
  ORDERS = 'bestellingen',
  CHECK_DELIVERY = 'controle-levering',
  INDEX = 'index',
  LOGIN = 'inloggen',
  CUSTOMER_PROFILE = 'klantprofiel',
  DELIVERY_OVERVIEW = 'leveringen-over',
  BACKORDERS = 'nazendingen',
  PARALLEL_ORDER = 'parallel-bestellen',
  PRINT = 'printen',
  PRESCRIPTION_PROCESSING = 'receptverwerking'
}

export enum UserGroups {
  Admin = 'Admin',
  Tech = 'Tech',
  Apothekers = 'Apothekers',
  Logistiek = 'Logistiek',
  Autoriseren = 'Autoriseren'
}

export enum Capability {
  PharmacistCheckView = 'PharmacistCheckView',
  PharmacistCheckJudge = 'PharmacistCheckJudge',
  PrescriptionProcessingView = 'PrescriptionProcessingView',
  PrescriptionProcessingJudge = 'PrescriptionProcessingJudge'
}

export type PageCapabilities = Record<Capability, UserGroups[]>

export const CAPABILITY_CONFIG = new Map<PageName, PageCapabilities>()
  .set(PageName.PHARMACIST_INSPECTION, {
    PharmacistCheckView: [UserGroups.Apothekers],
    PharmacistCheckJudge: [UserGroups.Apothekers]
  } as PageCapabilities)
  .set(PageName.PRESCRIPTION_PROCESSING, {
    PrescriptionProcessingView: [UserGroups.Logistiek, UserGroups.Autoriseren],
    PrescriptionProcessingJudge: [UserGroups.Logistiek]
  } as PageCapabilities)
