import {
  DocumentData,
  DocumentReference,
  query,
  Timestamp,
  where,
  WithFieldValue
} from 'firebase/firestore'
import { Lock, LockType } from '~/models/Lock'
import { ArrayService } from '../service'

export class LockService extends ArrayService<Lock> {
  private document: <Lock extends FirestoreType>(
    collectionName: string,
    ...pathSegments: string[]
  ) => DocumentReference<Lock, DocumentData>
  private setDocument: <Lock extends FirestoreType>(
    reference: DocumentReference<Lock, DocumentData>,
    data: Omit<WithFieldValue<Lock>, keyof FirestoreType>
  ) => Promise<void>
  private user

  protected activeLock?: Lock
  protected activeTimer?: NodeJS.Timeout

  // Only return locks that are within the last hour
  public readonly SEARCH_RANGE: number = 3600000
  // Heartbeat interval is how often the lock will update the TTL
  public readonly HEARTBEAT_INTERVAL: number = 15000
  // Push ValidUntil 30 seconds into the future on each heartbeat
  public readonly HEARTBEAT_PUSH: number = 30000

  constructor() {
    super('Locks')
    const { doc, setDoc, user } = useFirebase()
    this.document = doc
    this.setDocument = setDoc
    this.user = user
  }

  public listenForType(type: LockType): void {
    const rangeMillis = Date.now() - this.SEARCH_RANGE
    const listenQuery = query(
      this.collection,
      where('Type', '==', type),
      where('ValidUntil', '>=', Timestamp.fromMillis(rangeMillis))
    )
    this.listen(listenQuery)
  }

  public async lock(type: LockType, typeId: string): Promise<void> {
    console.log(`Locking ${type}: ${typeId}`)
    this.activeLock = {
      Type: type,
      TypeId: typeId,
      LockedBy: this.user.value.displayName,
      ValidUntil: Timestamp.fromMillis(Date.now() + this.HEARTBEAT_PUSH)
    } as Lock
    await this.heartbeat()
    // Set a heartbeat timer to update every HEARTBEAT_INTERVAL milliseconds
    // The timer will NOT wait for the async function to complete
    this.activeTimer = setInterval(
      this.heartbeat.bind(this),
      this.HEARTBEAT_INTERVAL
    )
  }

  public async unlock(): Promise<void> {
    if (!this.activeLock) {
      return
    }

    console.log('Unlocking current lock')
    clearInterval(this.activeTimer)
    const doc = this.document('Locks', this.user.value.uid)
    this.activeLock.ValidUntil = Timestamp.fromMillis(Date.now() - 1000)
    await this.setDocument(doc, this.activeLock)
    this.activeLock = undefined
  }

  public filterActiveLocks(locks: Lock[]): Lock[] {
    const filtered = []
    for (const lock of locks) {
      if (lock.ValidUntil.toMillis() >= Date.now()) {
        filtered.push(lock)
      }
    }
    return filtered
  }

  protected async heartbeat(): Promise<void> {
    /* v8 ignore next 3 - In here fore safety, should not be achievable */
    if (this.activeLock === undefined) {
      return
    }

    console.log(`Heartbeat: ${this.activeLock.Type} ${this.activeLock.TypeId}`)
    const doc = this.document('Locks', this.user.value.uid)
    const pushMillis = Date.now() + this.HEARTBEAT_PUSH
    this.activeLock.ValidUntil = Timestamp.fromMillis(pushMillis)
    await this.setDocument(doc, this.activeLock)
  }
}
