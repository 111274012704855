import { and, limit, or, query, where } from 'firebase/firestore'
import { MedicineArticle } from '~/models'
import { ArrayService } from './service'

export class MedicineArticleService extends ArrayService<MedicineArticle> {
  constructor() {
    super('MedicineArticles')
  }

  public listenForArticleNumber(articleNumber: number): void {
    const start =
      articleNumber * Math.pow(10, 8 - articleNumber.toString().length)

    const end =
      (articleNumber + 1) * Math.pow(10, 8 - articleNumber.toString().length) -
      1

    const listenQuery = query(
      this.collection,
      where('ArticleNumber', '>=', start),
      where('ArticleNumber', '<=', end),
      limit(50)
    )
    this.listen(listenQuery)
  }

  public listenForArticleName(articleName: string): void {
    // Firestore does not have a built-in case-insensitive search
    // Therefore we'll look for article names starting with either a lowercase or an uppercase letter
    const listenQuery = query(
      this.collection,
      or(
        and(
          where('ArticleName', '>=', this.lowercaseFirstLetter(articleName)),
          where(
            'ArticleName',
            '<=',
            `${this.lowercaseFirstLetter(articleName)}\uF8FF`
          )
        ),
        and(
          where('ArticleName', '>=', this.capitalizeFirstLetter(articleName)),
          where(
            'ArticleName',
            '<=',
            `${this.capitalizeFirstLetter(articleName)}\uF8FF`
          )
        )
      ),

      limit(50)
    )
    this.listen(listenQuery)
  }

  private capitalizeFirstLetter(value: string) {
    return String(value).charAt(0).toUpperCase() + String(value).slice(1)
  }

  private lowercaseFirstLetter(value: string) {
    return String(value).charAt(0).toLowerCase() + String(value).slice(1)
  }
}
